/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { MetaComponent } from '../common';
import { useGetUserWithEvents } from '../common/graphQL';
import { EditUserContainer } from '../profile/EditUser.container';
import {TitleComponent} from "../common/ProfileTitle.component/Title.component";
import {Link} from "react-router-dom";
import {useLocalePrefix} from "../lang";

export const MyProfile = (props) => {
    const {
        location,
        user,
        id: urlId,
        onCheckUrl,
        workspace,
        isWhiteLabeled,
        ...other
    } = props;

    const { id, name } = user || {};
    const { formatMessage } = useIntl();
    const [localePrefix] = useLocalePrefix();
    const { getUser, user: userEvents } = useGetUserWithEvents();
    const [updatedData, setUpdatedData] = useState(null);

    const isWorkspaceCreator = useMemo(() => {
        if (workspace?.members) {
            const { members } = workspace;
            const member = members?.find(el => el?.id === user?.id);
            return member?.permission === 'Owner';
        }
    }, [user, workspace]);

    const metaData = useMemo(() => {
        const title = workspace ? workspace?.title : "Unschooler";
        const metaTitle = `${name}' / online learner on ${title}'`;
        const description = `${formatMessage({ id: 'edit_user_label' })} on ${title}`;
        const url = window.location.href;
        return {
            meta: {
                meta_title: metaTitle,
                meta_description: description,
                meta_author: name,
                meta_icon:  workspace && (workspace?.favicon || workspace?.logo),
            },
            og: {
                title: metaTitle,
                image: workspace && (workspace?.sharing || workspace?.cover),
                description,
                url,
            },
            canonical: url
        };
    }, [id, workspace]);

    const handleChangeSchedule = (data) => {
        setUpdatedData(data);
    };

    const waitProfile = async () => {
        await getUser(id, null, null, 50, null, "SUBSCRIBED");
    };

    useEffect(() => {
        if (id) {
            (async () => waitProfile())();
        }
    }, [id, urlId]);

    return (
        <>
            {metaData && (
                <MetaComponent
                    meta={metaData.meta}
                    og={metaData.og}
                    twitter={metaData.twitter}
                    canonical={metaData.canonical}
                />
            )}

            <div className="page min-h-100vh flex_direction-column">
                <div>
                    <TitleComponent title={formatMessage({ id: 'edit_user_label' })} back={true} />
                    <Link
                        className='inline-el link-btn'
                        to={`${localePrefix}/logout`}>
                        {formatMessage({ id: 'nav_exit' })}
                    </Link>
                </div>
                <EditUserContainer
                    isWhiteLabeled={isWhiteLabeled}
                    isCreator={isWorkspaceCreator}
                    {...other}
                />
            </div>
        </>
    );
};
